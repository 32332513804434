import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './characters.scss';
import { IReverseCharacter } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import lodash from 'lodash';
import { RevTagsArray } from '../../modules/reverse/common/components/rev-tags';
import { Reverse1999Character } from '../../modules/reverse/common/components/rev-character';

interface IReverseCharacterNodes {
  nodes: IReverseCharacter[];
}

interface IReverseCharacterEntry {
  allCharacters: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const ReverseCharactersPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '2✦', value: '2' },
          { label: '3✦', value: '3' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'afflatus',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Star',
            tooltip: 'Star',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_star.png"
                width={24}
                alt="Star"
              />
            )
          },
          {
            value: 'Plant',
            tooltip: 'Plant',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_plant.png"
                width={24}
                alt="Plant"
              />
            )
          },
          {
            value: 'Mineral',
            tooltip: 'Mineral',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_mineral.png"
                width={24}
                alt="Mineral"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Spirit',
            tooltip: 'Spirit',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_spirit.png"
                width={24}
                alt="Spirit"
              />
            )
          },
          {
            value: 'Intellect',
            tooltip: 'Intellect',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_intellect.png"
                width={24}
                alt="Intellect"
              />
            )
          }
        ]
      },
      {
        key: 'damageType',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mental',
            label: 'Mental'
          },
          {
            value: 'Reality',
            label: 'Reality'
          }
        ]
      },
      { key: 'tags', type: 'dropdown', values: RevTagsArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.afflatus && activeFilters.afflatus !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.afflatus.toLowerCase() === activeFilters.afflatus.toLowerCase()
        );
      }
      if (activeFilters.damageType && activeFilters.damageType !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType &&
            emp.damageType.length > 0 &&
            emp.damageType.indexOf(activeFilters.damageType) > -1
        );
      }
      if (activeFilters.tags && activeFilters.tags !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.tags &&
            emp.tags.length > 0 &&
            emp.tags.indexOf(activeFilters.tags) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke characters-reverse'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/reverse/categories/category_characters.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Characters</h1>
          <h2>List of available characters in Reverse: 1999.</h2>
          <p>
            Last updated: <strong>04/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} characters
          </span>
        }
      />
      <div className="employees-filter-bar">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug={emp.slug}
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/reverse/no_results.webp"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ReverseCharactersPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Reverse: 1999 | Prydwen Institute"
    description="List of available characters in Reverse: 1999 - both in Global and CN versions."
    game="reverse"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulReverseCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        tierListCategory
        tierListTags
        rarity
        afflatus
        damageType
        tags
        ratingsNew {
          base
          euphoria
        }
        tierComment {
          tierComment
        }
        releasePatch
        availableInGlobal
        isReviewPending
        isNew
      }
    }
  }
`;
